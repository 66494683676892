import React, { useState, useEffect, useMemo } from "react";
import M from "materialize-css";
import { Link, useHistory, useParams } from "react-router-dom";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";

// Component Function
const AddProductToBucket = (props) => {
  const history = useHistory();
  const { id, day } = useParams();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalRecords: 0,
    totalPages: 0,
  });

  const [allProductLoaded, setAllProductLoaded] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const selectProductHandler = (productId) => {
    if (formData.length) {
      const isExist = formData.some((value) => {
        return value.product == productId;
      });

      if (isExist) {
        const filteredArr = formData.filter((value) => {
          return value.product != productId;
        });
        setFormData([...filteredArr]);
      } else {
        setFormData([
          ...formData,
          {
            product: productId,
            quantity: 1,
          },
        ]);
      }
    } else {
      setFormData([
        ...formData,
        {
          product: productId,
          quantity: 1,
        },
      ]);
    }
  };

  const increaseQuantityHandler = (productId) => {
    const filteredArr = formData.map((value) => {
      if (value.product == productId) {
        return {
          product: productId,
          quantity: value.quantity + 1,
        };
      } else {
        return value;
      }
    });
    setFormData([...filteredArr]);
  };

  const decreaseQuantityHandler = (productId) => {
    const filteredArr = formData.map((value) => {
      if (value.product == productId && value.quantity > 1) {
        return {
          product: productId,
          quantity: value.quantity - 1,
        };
      } else {
        return value;
      }
    });
    setFormData([...filteredArr]);
  };

  // Get Bucket Details
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${Config.SERVER_URL}/buckets/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
          },
        });
        const result = await response.json();
        if (result.status == 200) {
          const productArray = result?.body[day]?.map((p) => {
            return {
              product: p?.product?._id,
              quantity: p.quantity,
            };
          });
          setFormData([...productArray]);
        } else {
          M.toast({ html: result.message, classes: "bg-danger" });
        }
      } catch (error) {
        M.toast({ html: error, classes: "bg-danger" });
      }
    };

    getData();
  }, []);

  // Submit Handler
  const submitHandler = (evt) => {
    evt.preventDefault();

    setUpdateLoading(true);

    fetch(`${Config.SERVER_URL}/buckets/${id}`, {
      method: "PUT",
      body: JSON.stringify({ [day]: formData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setUpdateLoading(false);
        },
        (error) => {
          setUpdateLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  const limitHandler = (e) => {
    const limit = e.target.value;
    const totalPage = Math.ceil(pagination.totalRecord / limit);
    setPagination({
      ...pagination,
      limit,
      totalPage,
    });
  };

  const pageHandler = (e, page) => {
    e.preventDefault();
    setPagination({
      ...pagination,
      skip: page == 1 ? 0 : (page - 1) * pagination.limit,
      currentPage: page,
    });
  };

  const previousPageHandler = (e) => {
    e.preventDefault();
    setPagination({
      ...pagination,
      currentPage: pagination.currentPage == 1 ? 1 : pagination.currentPage - 1,
      skip:
        pagination.currentPage == 1
          ? 0
          : (pagination.currentPage - 2) * pagination.limit,
    });
  };

  const nextPageHandler = (e) => {
    e.preventDefault();
    setPagination({
      ...pagination,
      currentPage:
        pagination.currentPage == pagination.totalPage
          ? pagination.totalPage
          : pagination.currentPage + 1,
      skip:
        pagination.currentPage == 1
          ? pagination.limit
          : (pagination.currentPage + 1) * pagination.limit,
    });
  };

  // Get Products
  useEffect(() => {
    let url = `${Config.SERVER_URL}/products?page=${pagination.page}&limit=${pagination.limit}`;
    if (searchQuery) url += `&searchQuery=${searchQuery}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAllProductLoaded(true);
          if (result.status === 200) {
            setAllProducts(result.body || []);
            setPagination({
              ...pagination,
              page: result.page,
              totalPages: result.totalPages,
              totalRecords: result.totalRecords,
            });
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setAllProductLoaded(true);
        }
      );
  }, [pagination.page, pagination.limit, searchQuery]);

  // Return function
  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"PRODUCT"} pageTitle={"Add Product"} />
        {/* End Bread crumb and right sidebar toggle */}
        {/* Form */}
        <div className="row mt-2">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Product Details */}
              <div className={"row shadow-sm bg-white py-3 mt-2"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Product Details</h3>
                </div>

                <div className={"col-md-12 px-0"}>
                  {/* Heading */}
                  <div className={"card mb-0 mt-2 border-0 rounded"}>
                    <div className={"card-body pb-0 pt-2 d-flex"}>
                      <div>
                        <h4 className="float-left mt-2 mr-2">Search: </h4>
                      </div>
                      <div className="border px-2">
                        <input
                          type="search"
                          onChange={(evt) => {
                            setSearchQuery(evt.target.value);
                          }}
                          placeholder="By Name"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Data */}
                  {allProductLoaded ? (
                    <div className="card border-0 rounded m-0 py-1">
                      {allProducts?.length ? (
                        <div className="card-body py-0">
                          <div className="table-responsive">
                            <table
                              className={
                                "table table-bordered table-striped my-0"
                              }
                            >
                              <thead>
                                <tr>
                                  <th>SN</th>
                                  <th>NAME</th>
                                  <th>IMAGE</th>
                                  <th>QUANTITY</th>
                                  <th className="text-center">ACTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allProducts.map((product, index) => {
                                  return (
                                    <tr key={product.id}>
                                      <td>{index + 1}</td>
                                      <td>{product.name}</td>
                                      <td>
                                        <img
                                          style={{
                                            height: "100px",
                                            width: "100px",
                                            borderRadius: "50px",
                                          }}
                                          src={product.defaultImage}
                                        />
                                      </td>
                                      <td>
                                        {formData.some((value) => {
                                          return value.product == product._id;
                                        }) ? (
                                          <div className="d-flex bucket-product">
                                            <button
                                              onClick={() => {
                                                decreaseQuantityHandler(
                                                  product._id
                                                );
                                              }}
                                              className="qty-btn"
                                              type="button"
                                            >
                                              -
                                            </button>
                                            <input
                                              className="qty"
                                              type="text"
                                              value={
                                                formData.filter((value) => {
                                                  return (
                                                    value.product == product._id
                                                  );
                                                })[0].quantity
                                              }
                                            />
                                            <button
                                              onClick={() => {
                                                increaseQuantityHandler(
                                                  product._id
                                                );
                                              }}
                                              className="qty-btn"
                                              type="button"
                                            >
                                              +
                                            </button>
                                          </div>
                                        ) : null}
                                      </td>

                                      <td className="text-center">
                                        <div className="form-check form-check-inline">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={formData.some((value) => {
                                              return (
                                                value.product == product._id
                                              );
                                            })}
                                            id={`product-${index}`}
                                            onChange={(evt) =>
                                              selectProductHandler(product._id)
                                            }
                                          />

                                          <label
                                            className="form-check-label"
                                            htmlFor={`product-${index}`}
                                          ></label>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            {/* Pagination */}
                            <Pagination
                              pagination={pagination}
                              setPagination={setPagination}
                              tableName="table-to-xls"
                              csvFileName="products"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            "alert alert-danger mx-3 rounded border-0 py-2"
                          }
                        >
                          No Data Available
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={"bg-white p-3 text-center"}>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  )}
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    disabled={updateLoading}
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {updateLoading ? (
                      <Spinner />
                    ) : (
                      <div>
                        <i className="fas fa-plus"></i> Update
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductToBucket;
