import React, { createContext, useReducer, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import LeftNavigation from "./LeftNavigation";
import TopNavigation from "./TopNavigation";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

import { initialState, adminReducer } from "../reducer/AdminReducer";

// import PageNoteFound from "./pages/PageNotFound";

// import ParentCategory from "./pages/ParentCategory";
// import Category from "./pages/Category";

// ================   Admin  =====================
import Profile from "./pages/Profile";

// ================   Products  =====================
import AddProduct from "./pages/products/AddProduct";
import ProductList from "./pages/products/ProductList";
import EditProduct from "./pages/products/EditProduct";
import AddProductFromCSV from "./pages/products/AddProductFromCSV";
import EditProductFromCSV from "./pages/products/EditProductFromCSV";
import ProductSizes from "./pages/products/ProductSizes";
import ProductColors from "./pages/products/ProductColors";
import ProductColorsAndSizes from "./pages/products/ProductColorsAndSizes";

// ================   Reviews  =====================
import ReviewList from "./pages/reviews/ReviewList";
import EditReview from "./pages/reviews/EditReview";

// ================   Sub Admins  =====================
import AdminList from "./pages/subAdmins/AdminList";
import AddAdmin from "./pages/subAdmins/AddAdmin";
import EditAdmin from "./pages/subAdmins/EditAdmin";

// ================   Coupons  =====================
import AddCoupon from "./pages/coupons/AddCoupon";
import CouponList from "./pages/coupons/CouponList";
import EditCoupon from "./pages/coupons/EditCoupon";
import EditCouponFromCSV from "./pages/coupons/EditCouponFromCSV";
import AddCouponFromCSV from "./pages/coupons/AddCouponFromCSV";

// ================   Occasions  =====================
import AddOccasion from "./pages/occasions/AddOccasion";
import OccasionList from "./pages/occasions/OccasionList";
import EditOccasion from "./pages/occasions/EditOccasion";
import EditOccasionFromCSV from "./pages/occasions/EditOccasionFromCSV";
import AddOccasionFromCSV from "./pages/occasions/AddOccasionFromCSV";

// ================ Colors ===========================
import ColorList from "./pages/colors/ColorList";
import AddColor from "./pages/colors/AddColor";
import EditColor from "./pages/colors/EditColor";
import AddColorFromCSV from "./pages/colors/AddColorFromCSV";
import EditColorFromCSV from "./pages/colors/EditColorFromCSV";

// ================ Sizes ===========================
import SizeList from "./pages/sizes/SizeList";
import AddSize from "./pages/sizes/AddSize";
import EditSize from "./pages/sizes/EditSize";
import AddSizeFromCSV from "./pages/sizes/AddSizeFromCSV";
import EditSizeFromCSV from "./pages/sizes/EditSizeFromCSV";

// ================   Pincodes  =====================
import PincodeList from "./pages/pincode/PincodeList";
import AddPincode from "./pages/pincode/AddPincode";
import EditPincode from "./pages/pincode/EditPincode";
import EditPincodeFromCSV from "./pages/pincode/EditPincodeFromCSV";
import AddPincodeFromCSV from "./pages/pincode/AddPincodeFromCSV";

// ================   Areas  =====================
import AreaList from "./pages/areas/AreaList";
import AddArea from "./pages/areas/AddArea";
import EditArea from "./pages/areas/EditArea";
import AddAreaFromCSV from "./pages/areas/AddAreaFromCSV";
import EditAreaFromCSV from "./pages/areas/EditAreaFromCSV";

// import ShapeList from "./pages/shapes/ShapeList";
// import AddShape from "./pages/shapes/AddShape";
// import EditShape from "./pages/shapes/EditShape";

// Orders
import NewOrders from "./pages/orders/NewOrders";
import OrderDetails from "./pages/orders/OrderDetails";
import OrderList from "./pages/orders/OrderList";

import Setting from "./pages/setting/Setting";
import MainSlider from "./pages/banners/MainSlider";
import NextToSlider from "./pages/banners/NextToSlider";
import DailyBestSaleBanner from "./pages/banners/DailyBestSaleBanner";
import CategoryPageBanner from "./pages/banners/CategoryPageBanner";
import OfferBanner from "./pages/banners/OfferBanner";

// Category
import CategoryList from "./pages/category/CategoryList";
import AddCategory from "./pages/category/AddCategory";
import EditCategory from "./pages/category/EditCategory";
import AddCategoryFromCSV from "./pages/category/AddCategoryFromCSV";
import EditCategoryFromCSV from "./pages/category/EditCategoryFromCSV";

// Sub Category
import SubCategoryList from "./pages/subCategory/SubCategoryList";
import AddSubCategory from "./pages/subCategory/AddSubCategory";
import EditSubCategory from "./pages/subCategory/EditSubCategory";
import AddSubCategoryFromCSV from "./pages/subCategory/AddSubCategoryFromCSV";
import EditSubCategoryFromCSV from "./pages/subCategory/EditSubCategoryFromCSV";

// Sub Category
import TestimonialList from "./pages/testimonials/TestimonialList";
import AddTestimonial from "./pages/testimonials/AddTestimonial";
import EditTestimonial from "./pages/testimonials/EditTestimonial";
import AddTestimonialFromCSV from "./pages/testimonials/AddTestimonialFromCSV";
import EditTestimonialFromCSV from "./pages/testimonials/EditTestimonialFromCSV";

// Newsletter
import NewsletterList from "./pages/newsletters/NewsletterList";
import AddNewsletter from "./pages/newsletters/AddNewsletter";
import EditNewsletter from "./pages/newsletters/EditNewsletter";
import AddNewsletterFromCSV from "./pages/newsletters/AddNewsletterFromCSV";
import EditNewsletterFromCSV from "./pages/newsletters/EditNewsletterFromCSV";

// Faqs
import FaqsList from "./pages/faqs/FaqsList";
import AddFaqs from "./pages/faqs/AddFaqs";
import EditFaqs from "./pages/faqs/EditFaqs";
// import AddFaqsFromCSV from "./pages/faqs/AddFaqsFromCSV";
// import EditFaqsFromCSV from "./pages/faqs/EditFaqsFromCSV";

// Customer
import CustomerList from "./pages/customers/CustomerList";
import EditCustomer from "./pages/customers/EditCustomer";
import CustomerDetails from "./pages/customers/CustomerDetails";
import CustomerReports from "./pages/reports/CustomerReports";
import ProductReports from "./pages/reports/ProductReports";

// import SubCategoryList from "./pages/subCategory/SubCategoryList";

// import AddSubCategory from "./pages/subCategory/AddSubCategory";
// import EditSubCategory from "./pages/subCategory/EditSubCategory";
// import AddSubCategoryFromCSV from "./pages/subCategory/AddSubCategoryFromCSV";
// import EditSubCategoryFromCSV from "./pages/subCategory/EditSubCategoryFromCSV";
import ForgotPassword from "./pages/ForgotPassword";
import EnterOtp from "./pages/EnterOtp";
import CreateNewPassword from "./pages/CreateNewPassword";
import Footer from "./Footer";

// Supervisor
import SupervisorList from "./pages/supervisors/SupervisorList";
import AddSupervisor from "./pages/supervisors/AddSupervisor";
import EditSupervisor from "./pages/supervisors/EditSupervisor";
import SupervisorDashboard from "./pages/supervisors/SupervisorDashboard";
import SupervisorDeliveryBoys from "./pages/supervisors/SupervisorDeliveryBoys";
import AssignPincodes from "./pages/supervisors/AssignPincodes";
import AssignDeliveryBoys from "./pages/supervisors/AssignDeliveryBoys";
import SupervisorPincodeList from "./pages/supervisors/SupervisorPincodeList";
import SupervisorAreaList from "./pages/supervisors/SupervisorAreaList";
import SupervisorCustomers from "./pages/supervisors/SupervisorCustomers";

// Delivery Boy
import DeliveryBoyList from "./pages/deliveryBoys/DeliveryBoyList";
import AddDeliveryBoy from "./pages/deliveryBoys/AddDeliveryBoy";
import EditDeliveryBoy from "./pages/deliveryBoys/EditDeliveryBoy";
import DeliveryBoyDetails from "./pages/deliveryBoys/DeliveryBoyDetails";

// Bucket List
import BucketList from "./pages/buckets/BucketList";
import AddBucket from "./pages/buckets/AddBucket";
import EditBucket from "./pages/buckets/EditBucket";
import BucketDetails from "./pages/buckets/BucketDetails";
import AddProductToBucket from "./pages/buckets/AddProductToBucket";

// Subscription
import SubscriptionsList from "./pages/subscriptions/SubscriptionsList";
import SubscriptionDetails from "./pages/subscriptions/SubscriptionDetails";
import SubscriptionHistoryList from "./pages/subscriptions/SubscriptionHistoryList";
import SubscriptionForDelivery from "./pages/subscriptions/SubscriptionForDelivery";

// Blog Category
import BlogCategoryList from "./pages/blogCategory/BlogCategoryList";
import AddBlogCategory from "./pages/blogCategory/AddBlogCategory";
import EditBlogCategory from "./pages/blogCategory/EditBlogCategory";

// Blog Post
import BlogPostList from "./pages/blogPosts/BlogPostList";
import AddBlogPost from "./pages/blogPosts/AddBlogPost";
import EditBlogPost from "./pages/blogPosts/EditBlogPost";

import AssignAreas from "./pages/supervisors/AssignAreas";
import AssignCustomers from "./pages/supervisors/AssignCustomers";

import OrderHistoryList from "./pages/orders/OrderHistoryList";
import AddBucketFromCSV from "./pages/buckets/AddBucketFromCSV";
import EditBucketFromCSV from "./pages/buckets/EditBucketFromCSV";
import AddInquires from "./pages/inquiries/AddInquires";
import InquiryList from "./pages/inquiries/InquiryList";
import EditInquiry from "./pages/inquiries/EditInquiry";
import MainMenuList from "./pages/mainMenus/MainMenuList";
import AddMainMenu from "./pages/mainMenus/AddMainMenu";
import EditMainMenu from "./pages/mainMenus/EditMainMenu";
import AddInquiryFromCSV from "./pages/inquiries/AddInquiryFromCSV";
import EditInquiryFromCSV from "./pages/inquiries/EditInquiryFromCSV";
import PrivacyPolicy from "./pages/webContents/PrivacyPolicy";
import TermsConditions from "./pages/webContents/TermsAndConditions";
import ContactUs from "./pages/webContents/ContactUs";
import AboutUs from "./pages/webContents/AboutUs";
import Config from "../config/Config";
import PromotionBanner from "./pages/banners/PromotionBanner";
import ListingBanner from "./pages/banners/ListingBanner";
import ReturnRefundPolicy from "./pages/webContents/ReturnRefundPolicy";
import ProjectCategoryList from "./pages/projectCategory/ProjectCategoryList";
import AddProjectCategory from "./pages/projectCategory/AddProjectCategory";
import EditProjectCategory from "./pages/projectCategory/EditProjectCategory";
import ProjectSubCategoryList from "./pages/projectSubCategory/ProjectSubCategoryList";
import AddProjectSubCategory from "./pages/projectSubCategory/AddProjectSubCategory";
import EditProjectSubCategory from "./pages/projectSubCategory/EditProjectSubCategory";
import ProjectChildCategoryList from "./pages/projectChildCategory/ProjectChildCategoryList";
import AddProjectChildCategory from "./pages/projectChildCategory/AddProjectChildCategory";
import EditProjectChildCategory from "./pages/projectChildCategory/EditProjectChildCategory";
import ProjectList from "./pages/project/ProjectList";
import AddProject from "./pages/project/AddProject";
import EditProject from "./pages/project/EditProject";
import InquiryDetails from "./pages/inquiries/InquiryDetails";

// Create Context
export const AdminContext = createContext();

// Create Context
const Routing = () => {
  const history = useHistory();
  // Branch Context
  const { state, dispatch } = useContext(AdminContext);
  useEffect(() => {
    const admin = localStorage.getItem("jwt_admin_token");
    if (admin) {
      dispatch({ type: "ADMIN", payload: {} });
      // history.push("/")
    } else {
      history.push("/admin/login");
    }
  }, []);

  useEffect(() => {
    async function checkToken() {
      const jwt_admin_token = localStorage.getItem("jwt_admin_token");
      if (jwt_admin_token) {
        // get admin profile

        const apiResponse = await fetch(Config.SERVER_URL + "/admins/profile", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt_admin_token}`,
          },
        });

        const apiData = await apiResponse.json();
        if (apiData.status == 200) {
          dispatch({ type: "ADMIN", payload: apiData.body });
        } else {
          history.push("/admin/login");
        }
      } else {
        history.push("/admin/login");
      }
    }

    checkToken();
  }, []);

  // it is not working
  useEffect(() => {
    // Load external script.js file
    const script = document.createElement("script");
    script.src = "/js/sidebarmenu.js"; // Replace with the actual path to your script.js
    // script.async = true;

    script.onload = () => {
      // Code to execute after the script has loaded (if needed)
      console.log("Script.js has been loaded.");
    };

    document.body.appendChild(script);

    // Clean up the script tag when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [state]);

  return (
    <Switch>
      <Route exact path="/admin" component={Dashboard} />
      <Route exact path="/admin/login" component={Login} />
      <Route exact path="/admin/forgot-password" component={ForgotPassword} />
      <Route exact path="/admin/enter-otp" component={EnterOtp} />
      <Route
        exact
        path="/admin/create-password"
        component={CreateNewPassword}
      />{" "}
      <Route exact path="/admin/profile" component={Profile} />
      <Route exact path="/admin/setting" component={Setting} />
      <Route exact path="/admin/slider" component={MainSlider} />
      <Route exact path="/admin/nextToSlider" component={NextToSlider} />
      <Route exact path="/admin/nextToSlider" component={NextToSlider} />
      <Route
        exact
        path="/admin/bestSaleBanner"
        component={DailyBestSaleBanner}
      />
      <Route
        exact
        path="/admin/categoryPageBanner"
        component={CategoryPageBanner}
      />
      <Route exact path="/admin/shopPageBanner" component={ListingBanner} />
      <Route exact path="/admin/offerBanner" component={OfferBanner} />
      <Route exact path="/admin/promotionBanner" component={PromotionBanner} />
      {/* Coupons */}
      <Route exact path="/admin/coupons" component={CouponList} />
      <Route exact path="/admin/coupon/add" component={AddCoupon} />
      <Route exact path="/admin/coupon/addByCSV" component={AddCouponFromCSV} />
      <Route
        exact
        path="/admin/coupon/editByCSV"
        component={EditCouponFromCSV}
      />
      <Route exact path="/admin/coupon/edit/:id" component={EditCoupon} />
      {/*  Pincode */}
      <Route exact path="/admin/pincodes" component={PincodeList} />
      <Route exact path="/admin/pincode/add" component={AddPincode} />
      <Route
        exact
        path="/admin/pincode/addByCSV"
        component={AddPincodeFromCSV}
      />
      <Route
        exact
        path="/admin/pincode/editByCSV"
        component={EditPincodeFromCSV}
      />
      <Route exact path="/admin/pincode/edit/:id" component={EditPincode} />
      {/*  Areas */}
      <Route exact path="/admin/areas" component={AreaList} />
      <Route exact path="/admin/area/add" component={AddArea} />
      <Route exact path="/admin/area/addByCSV" component={AddAreaFromCSV} />
      <Route exact path="/admin/area/editByCSV" component={EditAreaFromCSV} />
      <Route exact path="/admin/area/edit/:id" component={EditArea} />
      {/* Project Categories */}
      <Route
        exact
        path="/admin/projectCategories"
        component={ProjectCategoryList}
      />
      <Route
        exact
        path="/admin/projectCategories/add"
        component={AddProjectCategory}
      />
      <Route
        exact
        path="/admin/projectCategories/edit/:id"
        component={EditProjectCategory}
      />
      {/* End Project Categories */}
      {/* Project Sub Categories */}
      <Route
        exact
        path="/admin/projectSubCategories"
        component={ProjectSubCategoryList}
      />
      <Route
        exact
        path="/admin/projectSubCategories/add"
        component={AddProjectSubCategory}
      />
      <Route
        exact
        path="/admin/projectSubCategories/edit/:id"
        component={EditProjectSubCategory}
      />
      {/* End Project Sub Categories */}
      {/* Project Child Categories */}
      <Route
        exact
        path="/admin/projectChildCategories"
        component={ProjectChildCategoryList}
      />
      <Route
        exact
        path="/admin/projectChildCategories/add"
        component={AddProjectChildCategory}
      />
      <Route
        exact
        path="/admin/projectChildCategories/edit/:id"
        component={EditProjectChildCategory}
      />
      {/* End Project Sub Categories */}
      {/* Projects */}
      <Route exact path="/admin/projects" component={ProjectList} />
      <Route exact path="/admin/projects/add" component={AddProject} />
      <Route exact path="/admin/projects/edit/:id" component={EditProject} />
      {/* End Project Sub Categories */}
      {/* Categories */}
      <Route exact path="/admin/categories" component={CategoryList} />
      <Route exact path="/admin/category/add" component={AddCategory} />
      <Route
        exact
        path="/admin/category/addByCSV"
        component={AddCategoryFromCSV}
      />
      <Route
        exact
        path="/admin/category/editByCSV"
        component={EditCategoryFromCSV}
      />
      <Route exact path="/admin/category/edit/:id" component={EditCategory} />
      {/* Sub Categories */}
      <Route exact path="/admin/subCategories" component={SubCategoryList} />
      <Route exact path="/admin/subCategory/add" component={AddSubCategory} />
      <Route
        exact
        path="/admin/subCategory/addByCSV"
        component={AddSubCategoryFromCSV}
      />
      <Route
        exact
        path="/admin/subCategory/editByCSV"
        component={EditSubCategoryFromCSV}
      />
      <Route
        exact
        path="/admin/subCategory/edit/:id"
        component={EditSubCategory}
      />
      {/* Testimonials */}
      <Route exact path="/admin/testimonials" component={TestimonialList} />
      <Route exact path="/admin/testimonial/add" component={AddTestimonial} />
      <Route
        exact
        path="/admin/testimonial/addByCSV"
        component={AddTestimonialFromCSV}
      />
      <Route
        exact
        path="/admin/testimonial/editByCSV"
        component={EditTestimonialFromCSV}
      />
      <Route
        exact
        path="/admin/testimonial/edit/:id"
        component={EditTestimonial}
      />
      {/* Occasions */}
      <Route exact path="/admin/occasions" component={OccasionList} />
      <Route exact path="/admin/occasion/add" component={AddOccasion} />
      <Route
        exact
        path="/admin/occasion/addByCSV"
        component={AddOccasionFromCSV}
      />
      <Route
        exact
        path="/admin/occasion/editByCSV"
        component={EditOccasionFromCSV}
      />
      <Route exact path="/admin/occasion/edit/:id" component={EditOccasion} />
      {/* Colors */}
      <Route exact path="/admin/colors" component={ColorList} />
      <Route exact path="/admin/color/add" component={AddColor} />
      <Route exact path="/admin/color/addByCSV" component={AddColorFromCSV} />
      <Route exact path="/admin/color/editByCSV" component={EditColorFromCSV} />
      <Route exact path="/admin/color/edit/:id" component={EditColor} />
      {/* Sizes */}
      <Route exact path="/admin/sizes" component={SizeList} />
      <Route exact path="/admin/size/add" component={AddSize} />
      <Route exact path="/admin/size/addByCSV" component={AddSizeFromCSV} />
      <Route exact path="/admin/size/editByCSV" component={EditSizeFromCSV} />
      <Route exact path="/admin/size/edit/:id" component={EditSize} />
      {/* Supervisor */}
      <Route exact path="/admin/supervisors" component={SupervisorList} />
      <Route exact path="/admin/supervisor/add" component={AddSupervisor} />
      <Route
        exact
        path="/admin/supervisor/edit/:id"
        component={EditSupervisor}
      />
      <Route
        exact
        path="/admin/supervisor/dashboard/:id"
        component={SupervisorDashboard}
      />
      <Route
        exact
        path="/admin/supervisor/assignPincodes/:id"
        component={AssignPincodes}
      />
      <Route
        exact
        path="/admin/supervisor/assignDeliveryBoys/:id"
        component={AssignDeliveryBoys}
      />
      <Route
        exact
        path="/admin/supervisor/deliveryBoys/:id"
        component={SupervisorDeliveryBoys}
      />
      <Route
        exact
        path="/admin/supervisor/pincodes/:id"
        component={SupervisorPincodeList}
      />
      <Route
        exact
        path="/admin/supervisor/areas/:id"
        component={SupervisorAreaList}
      />
      <Route
        exact
        path="/admin/supervisor/assignAreas/:id"
        component={AssignAreas}
      />
      <Route
        exact
        path="/admin/supervisor/customers/:id"
        component={SupervisorCustomers}
      />
      <Route
        exact
        path="/admin/supervisor/assignCustomers/:id"
        component={AssignCustomers}
      />
      {/* Delivery boys */}
      <Route exact path="/admin/deliveryBoys" component={DeliveryBoyList} />
      <Route exact path="/admin/deliveryBoy/add" component={AddDeliveryBoy} />
      <Route
        exact
        path="/admin/deliveryBoy/edit/:id"
        component={EditDeliveryBoy}
      />
      <Route
        exact
        path="/admin/deliveryBoy/details/:id"
        component={DeliveryBoyDetails}
      />
      {/* Newsletter */}
      <Route exact path="/admin/newsletters" component={NewsletterList} />
      <Route exact path="/admin/newsletter/add" component={AddNewsletter} />
      <Route
        exact
        path="/admin/newsletter/addByCSV"
        component={AddNewsletterFromCSV}
      />
      <Route
        exact
        path="/admin/newsletter/editByCSV"
        component={EditNewsletterFromCSV}
      />
      <Route
        exact
        path="/admin/newsletter/edit/:id"
        component={EditNewsletter}
      />
      {/* Newsletter */}
      <Route exact path="/admin/faqs" component={FaqsList} />
      <Route exact path="/admin/faqs/add" component={AddFaqs} />
      {/* <Route
        exact
        path="/admin/newsletter/addByCSV"
        component={AddNewsletterFromCSV}
      /> */}
      {/* <Route
        exact
        path="/admin/newsletter/editByCSV"
        component={EditNewsletterFromCSV}
      /> */}
      <Route exact path="/admin/faqs/edit/:id" component={EditFaqs} />
      {/* Blog Categories */}
      <Route exact path="/admin/blogCategories" component={BlogCategoryList} />
      <Route exact path="/admin/blogCategory/add" component={AddBlogCategory} />
      {/* <Route
        exact
        path="/admin/category/addByCSV"
        component={AddCategoryFromCSV}
      /> */}
      {/* <Route
        exact
        path="/admin/category/editByCSV"
        component={EditCategoryFromCSV}
      /> */}
      <Route
        exact
        path="/admin/blogCategory/edit/:id"
        component={EditBlogCategory}
      />
      {/* Blog Post */}
      <Route exact path="/admin/blogPosts" component={BlogPostList} />
      <Route exact path="/admin/blogPost/add" component={AddBlogPost} />
      {/* <Route
        exact
        path="/admin/blogPost/addByCSV"
        component={AddCategoryFromCSV}
      /> */}
      {/* <Route
        exact
        path="/admin/blogPost/editByCSV"
        component={EditCategoryFromCSV}
      /> */}
      <Route exact path="/admin/blogPost/edit/:id" component={EditBlogPost} />
      {/* Web Contents */}
      <Route exact path="/admin/privacyPolicy" component={PrivacyPolicy} />
      <Route exact path="/admin/termsConditions" component={TermsConditions} />
      <Route
        exact
        path="/admin/returnRefundPolicy"
        component={ReturnRefundPolicy}
      />
      <Route exact path="/admin/contactUs" component={ContactUs} />
      <Route exact path="/admin/aboutUs" component={AboutUs} />
      {/* Main Menu */}
      <Route exact path="/admin/mainMenus" component={MainMenuList} />
      <Route exact path="/admin/mainMenu/add" component={AddMainMenu} />
      <Route exact path="/admin/mainMenu/edit/:id" component={EditMainMenu} />
      {/* Inquires */}
      <Route exact path="/admin/inquires" component={InquiryList} />
      <Route exact path="/admin/inquiry/add" component={AddInquires} />
      <Route
        exact
        path="/admin/inquiry/addByCSV"
        component={AddInquiryFromCSV}
      />
      <Route
        exact
        path="/admin/inquiry/editByCSV"
        component={EditInquiryFromCSV}
      />
      <Route exact path="/admin/inquiry/edit/:id" component={EditInquiry} />
      <Route
        exact
        path="/admin/inquiry/details/:id"
        component={InquiryDetails}
      />
      {/* Customer */}
      <Route exact path="/admin/customers" component={CustomerList} />
      <Route exact path="/admin/customer/edit/:id" component={EditCustomer} />
      <Route
        exact
        path="/admin/customer/details/:id"
        component={CustomerDetails}
      />
      <Route exact path="/admin/report/customers" component={CustomerReports} />
      <Route exact path="/admin/report/products" component={ProductReports} />
      {/* Admin */}
      <Route exact path="/admin/subAdmins" component={AdminList} />
      <Route exact path="/admin/subAdmin/add" component={AddAdmin} />
      <Route exact path="/admin/subAdmin/edit/:id" component={EditAdmin} />
      {/* Products */}
      <Route exact path="/admin/products" component={ProductList} />
      <Route exact path="/admin/product/add" component={AddProduct} />
      <Route
        exact
        path="/admin/product/productSizes/:id"
        component={ProductSizes}
      />
      <Route
        exact
        path="/admin/product/productColors/:id"
        component={ProductColors}
      />
      <Route
        exact
        path="/admin/product/productColorsAndSizes/:id"
        component={ProductColorsAndSizes}
      />
      <Route
        exact
        path="/admin/product/addByCSV"
        component={AddProductFromCSV}
      />
      <Route exact path="/admin/product/edit/:id" component={EditProduct} />
      <Route
        exact
        path="/admin/product/editByCSV"
        component={EditProductFromCSV}
      />
      {/* Reviews */}
      <Route exact path="/admin/reviews" component={ReviewList} />
      <Route exact path="/admin/review/edit/:id" component={EditReview} />
      {/* Buckets */}
      <Route exact path="/admin/buckets" component={BucketList} />
      <Route exact path="/admin/bucket/add" component={AddBucket} />
      <Route exact path="/admin/bucket/addByCSV" component={AddBucketFromCSV} />
      <Route
        exact
        path="/admin/bucket/editByCSV"
        component={EditBucketFromCSV}
      />
      <Route exact path="/admin/bucket/edit/:id" component={EditBucket} />
      <Route exact path="/admin/bucket/details/:id" component={BucketDetails} />
      <Route
        exact
        path="/admin/bucket/addProduct/:id/:day?"
        component={AddProductToBucket}
      />
      {/* <Route
        exact
        path="/admin/product/addByCSV"
        component={AddProductFromCSV}
      />
      
      <Route
        exact
        path="/admin/product/editByCSV"
        component={EditProductFromCSV}
      /> */}
      {/* Subscriptions */}
      <Route exact path="/admin/subscriptions" component={SubscriptionsList} />
      <Route
        exact
        path="/admin/subscription/details/:id"
        component={SubscriptionDetails}
      />
      {/* Subscription History */}
      <Route
        exact
        path="/admin/subscriptionHistories"
        component={SubscriptionHistoryList}
      />
      {/* Delivery */}
      <Route
        exact
        path="/admin/subscriptionsForDelivery"
        component={SubscriptionForDelivery}
      />
      {/* Orders */}
      <Route exact path="/admin/orders" component={OrderList} />
      <Route exact path="/admin/order/details/:id" component={OrderDetails} />
      {/* <Route exact path="/admin/bucket/edit/:id" component={EditBucket} /> */}
      {/* Order History */}
      <Route exact path="/admin/orderHistories" component={OrderHistoryList} />
    </Switch>
  );
};

const AdminRouter = () => {
  const [state, dispatch] = useReducer(adminReducer, initialState);
  return (
    <div id="main-wrapper">
      <AdminContext.Provider value={{ state: state, dispatch: dispatch }}>
        <Router>
          <TopNavigation />
          <LeftNavigation />
          <Routing />
          <Footer />
        </Router>
      </AdminContext.Provider>
    </div>
  );
};

export default AdminRouter;
