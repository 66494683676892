import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import date from "date-and-time";

export default function InquiryDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [isUpdateLoaded, setIsUpdateLoaded] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [inquiryDetails, setInquiryDetails] = useState({});

  // get Records
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/inquires/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setInquiryDetails(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsDataLoaded(true);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsDataLoaded(true);
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        <Breadcrumb
          title="INQUIRY DETAILS"
          pageTitle={"Inquiry Details"}
          pageLink={"#"}
        />

        {/* Update Inquiry Form */}
        <div className="row">
          {isDataLoaded ? (
            <div className={"col-md-11 mx-auto"}>
              <div className="card">
                <div className="card-body border-0 ">
                  <div className="text-center my-3">
                    <img
                      style={{ width: "80px", borderRadius: "50px" }}
                      src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                    />
                    <h5>{inquiryDetails.name}</h5>
                    <h6>{inquiryDetails.mobile}</h6>
                  </div>

                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <td>{inquiryDetails?.email}</td>
                      </tr>
                      <tr>
                        <th>Inquiry Type</th>
                        <td>{inquiryDetails?.inquiryType}</td>
                      </tr>
                      <tr>
                        <th>Subject</th>
                        <td>{inquiryDetails?.subject}</td>
                      </tr>
                      <tr>
                        <th>Intrests</th>
                        <td>{inquiryDetails?.intrests}</td>
                      </tr>
                      <tr>
                        <th>Organization</th>
                        <td>{inquiryDetails?.organization}</td>
                      </tr>
                      <tr>
                        <th>Pincode</th>
                        <td>{inquiryDetails?.pincode}</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td>
                          {inquiryDetails?.inquiryStatus === "OPEN" ? (
                            <span className="badge badge-danger">OPEN</span>
                          ) : inquiryDetails?.inquiryStatus === "INPROGRESS" ? (
                            <span className="badge badge-info">
                              IN PROGRESS
                            </span>
                          ) : inquiryDetails?.inquiryStatus === "PENDING" ? (
                            <span className="badge badge-warning">
                              IN PROGRESS
                            </span>
                          ) : (
                            <span className="badge badge-success">CLOSED</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Inquiry Date</th>
                        <td>
                          {date.format(
                            new Date(inquiryDetails?.createdAt),
                            "DD-MM-YYYY, HH:MM"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Message</th>
                        <td>{inquiryDetails?.message}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-11 mx-auto text-center bg-white py-5">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
