import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";
import { storage } from "../../../firebase/FirebaseConfig";
import $ from "jquery";

function ProductColors(props) {
  const { id } = useParams();
  // State Variable
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [productColors, setProductColors] = useState([]);

  const [colorAdded, setColorAdded] = useState(false);
  const [formData, setFormData] = useState({
    mrp: "",
    sellingPrice: "",
    stock: "",
    maximumOrderQuantity: 2,
    defaultImage: "",
    images: [],
  });
  const [updateFormData, setUpdateFormData] = useState({
    mrp: "",
    sellingPrice: "",
    stock: "",
    maximumOrderQuantity: 2,
  });

  const [defaultImages, setDefaultImages] = useState("");
  const [defaultImgProgress, setDefaultImgProgress] = useState("");
  const [previewImages, setPreviewImages] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);

  const [colors, setColors] = useState([]);
  const [sizeDeleting, setSizeDeleting] = useState(false);
  const [sizeDeleted, setSizeDeleted] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [sizeUpdating, setSizeUpdating] = useState(false);
  const [sizeUpdated, setSizeUpdated] = useState(false);

  const [inputFields, setInputFields] = useState([
    { mrp: "", sellingPrice: "", stock: "", maximumOrderQuantity: 2 },
  ]);
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      { mrp: "", sellingPrice: "", stock: "", maximumOrderQuantity: 2 },
    ]);
  };
  const handleRemoveFields = (index) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields.splice(index, 1);
    setInputFields(updatedInputFields);
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoading(true);
    evt.preventDefault();
    const newColor = {
      ...formData,
      product: id,
    };

    fetch(Config.SERVER_URL + "/productColors", {
      method: "POST",
      body: JSON.stringify(newColor),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            $("#addModalCloseButton").click();
            // setInputFields([
            //   {
            //     mrp: "",
            //     sellingPrice: "",
            //     stock: "",
            //     maximumOrderQuantity: 2,
            //   },
            // ]);
            setColorAdded(!colorAdded);
            setFormData({
              mrp: "",
              sellingPrice: "",
              stock: "",
              maximumOrderQuantity: 2,
              defaultImage: "",
              images: [],
            });
            setDefaultImages("");
            setDefaultImgProgress("");
            setPreviewImages([]);
            setProgressInfos([]);
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoading(false);
        },
        (error) => {
          setIsAddLoading(false);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // get Colors
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/colors?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setColors(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Product Colors
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/productColors?product=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setProductColors(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [id, colorAdded, sizeDeleted, sizeUpdated]);

  // Delete Submit Handler
  const deleteSubmitHandler = () => {
    setSizeDeleting(true);

    fetch(`${Config.SERVER_URL}/productColors/${deleteId}`, {
      method: "DELETE",
      // body: JSON.stringify({deleteId}),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status == 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            setDeleteId("");
            setSizeDeleted(!sizeDeleted);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          $("#closeDeleteSizeModalButton").click();
          setSizeDeleting(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setSizeDeleting(false);
        }
      );
  };

  // Update Handler
  const updateHandler = (evt) => {
    evt.preventDefault();
    setSizeUpdating(true);
    const updateProductColor = {
      size: updateFormData?.size?.value,
      mrp: updateFormData?.mrp,
      sellingPrice: updateFormData?.sellingPrice,
      stock: updateFormData?.stock,
      maximumOrderQuantity: updateFormData?.maximumOrderQuantity,
    };

    fetch(`${Config.SERVER_URL}/productColors/${updateFormData._id}`, {
      method: "PUT",
      body: JSON.stringify(updateProductColor),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setSizeUpdating(false);
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            $("#updateModalCloseButton").click();
            setSizeUpdated(!sizeUpdated);

            setUpdateFormData({
              mrp: "",
              sellingPrice: "",
              stock: "",
              maximumOrderQuantity: 2,
            });
          } else {
            const errorKeys = Object.keys(result.errors);
            errorKeys.forEach((key) => {
              M.toast({ html: result.errors[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setSizeUpdating(false);
        }
      );
  };

  // Image Change
  const imageChangeHandler = (event, type) => {
    if (type == "default_image") {
      handleUpload(event.target.files[0], "", type);
    } else {
      if (event.target.files && event.target.files.length) {
        [...event.target.files].map((value, index) => {
          handleUpload(value, index);
        });
      }
    }
  };

  // File Delete Handler
  const fileDeleteHandler = (image, index, type) => {
    // Create a reference to the file to delete
    const fileRef = storage.refFromURL(image);
    // Delete the file
    fileRef
      .delete()
      .then(() => {
        // File deleted successfully
        if (type == "default_image") {
          setDefaultImages("");
          setDefaultImgProgress("");
        } else {
          let pImages = [...previewImages];
          pImages.splice(index, 1);

          let pInfos = [...progressInfos];
          pInfos.splice(index, 1);
          setProgressInfos(pInfos);
          setPreviewImages(pImages);
        }
      })
      .catch((error) => {
        // Uh-oh, an error occurred
        M.toast({ html: error, classes: "bg-danger" });
      });
  };

  // Upload Image
  const handleUpload = (image, i, type) => {
    const uploadTask = storage.ref(`products/${image.name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        if (type == "default_image") {
          setDefaultImgProgress(progress);
        } else {
          let arrs = [...progressInfos];
          arrs[i] = progress;
          setProgressInfos((old) => {
            return [...arrs];
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("products")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            if (type == "default_image") {
              setDefaultImages(url);
              setFormData((old) => {
                return {
                  ...old,
                  defaultImage: url,
                };
              });
            } else {
              setPreviewImages((old) => [...old, url]);
              setFormData((old) => {
                return {
                  ...old,
                  images: [...old.images, url],
                };
              });
            }
          });
      }
    );
  };

  return (
    <>
      <div className="page-wrapper px-0 pt-0">
        <div className={"container-fluid"}>
          {/* Bread crumb and right sidebar toggle */}
          <Breadcrumb
            title={"PRODUCT COLORS"}
            pageTitle={"Add Product Color"}
          />

          {/* End Bread crumb and right sidebar toggle */}

          {/* PRODUCT COLOR LIST */}
          <div className="row mt-2">
            <div className={"col-md-12 mx-auto"}>
              {/* PRODUCT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h3 className={"my-3 text-info"}>PRODUCT COLOR LIST</h3>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#addColorModal"
                      >
                        Add Color
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 table-responsive">
                  <table className="table table-striped">
                    <tr>
                      <th>#SN</th>
                      <th>COLOR</th>
                      <th>PRICE</th>
                      <th>STOCK</th>
                      <th>IMAGE</th>
                      <th>STATUS</th>
                      <th>ACTION</th>
                    </tr>
                    {productColors.map((productColor, index) => {
                      console.log(productColor);

                      return (
                        <tr>
                          <td>{`#${++index}`}</td>
                          <td>{productColor?.color?.name || "N/A"}</td>
                          <td>
                            <p className="mrp">Rs {productColor.mrp}</p>

                            <span className="text-success">
                              Rs {productColor.sellingPrice}
                            </span>
                          </td>
                          <td>{productColor.stock}</td>
                          <td>
                            {productColor.defaultImage ? (
                              <img
                                src={productColor.defaultImage}
                                style={{
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "35px",
                                }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{productColor.status ? "Active" : "Disabled"}</td>
                          <td>
                            <button
                              className="btn btn-info"
                              data-toggle="modal"
                              data-target="#updateSizeModal"
                              onClick={(event) => {
                                setUpdateFormData({
                                  _id: productColor._id,
                                  mrp: productColor.mrp,
                                  sellingPrice: productColor.sellingPrice,
                                  stock: productColor.stock,
                                  size: {
                                    label: productColor?.size?.name,
                                    value: productColor?.size?._id,
                                  },
                                  maximumOrderQuantity:
                                    productColor?.maximumOrderQuantity,
                                });
                              }}
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                            <button
                              className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#deleteSizeModal"
                              onClick={(e) => {
                                setDeleteId(productColor._id);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Product Color Form */}
          {/* <div className="row mt-2">
            <div className={"col-md-12 mx-auto"}>
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT COLOR</h3>
                </div>

                <div className="col-md-12">
                  <div className={"row bg-white py-3"}>
                    <div className="col-md-12">
                      <form
                        action=""
                        className="form-horizontal form-material"
                        onSubmit={submitHandler}
                      >
                        <div className="table-responsive overflow-none">
                          <table className="table table-striped">
                            <tr>
                              <th>Color *</th>
                              <th>Product MRP *</th>
                              <th>Selling Price</th>
                              <th>QTY/Stock *</th>
                              <th>Max Order Qty *</th>
                              <th></th>
                            </tr>

                            {inputFields.map((inputField, index) => (
                              <tr>
                                <td>
                                  <div className={"form-group overflow-none"}>
                                    <Select
                                      options={colors}
                                      onChange={({ value }) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].color = value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product mrp"
                                      value={inputField.mrp}
                                      className="form-control border"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].mrp =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product selling price"
                                      value={inputField.sellingPrice}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].sellingPrice =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter product stock"
                                      value={inputField.stock}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[index].stock =
                                          e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <div key={index} className="form-group">
                                    <input
                                      type="number"
                                      required
                                      placeholder="Enter max order qty"
                                      value={inputField.maximumOrderQuantity}
                                      className="form-control"
                                      onChange={(e) => {
                                        const updatedInputFields = [
                                          ...inputFields,
                                        ];
                                        updatedInputFields[
                                          index
                                        ].maximumOrderQuantity = e.target.value;
                                        setInputFields(updatedInputFields);
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                    className="btn btn-danger"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>

                        <div className="col-md-12">
                          <button
                            type="button"
                            onClick={handleAddFields}
                            className="btn btn-info"
                          >
                            Add Field
                          </button>

                          <button
                            type="submit"
                            className="btn btn-success ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* Add Color Model */}
      <div
        className="modal fade"
        id="addColorModal"
        tabindex="-1"
        aria-labelledby="addColorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addColorModalLabel">
                Add Color
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal form-material"
                onSubmit={submitHandler}
                id="addColorForm"
              >
                <div className="row">
                  {/* SELECT COLOUR */}
                  <div className={"form-group overflow-none col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      SELECT COLOUR
                    </label>
                    <div className="">
                      <Select
                        options={colors}
                        onChange={({ value }) => {
                          setFormData({ ...formData, color: value });
                        }}
                      />
                    </div>
                  </div>

                  {/* PRODUCT MRP */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      PRODUCT MRP
                    </label>
                    <input
                      type="number"
                      value={formData.mrp}
                      onChange={(evt) =>
                        setFormData({ ...formData, mrp: evt.target.value })
                      }
                      className="form-control"
                      placeholder={"Enter product mrp"}
                    />
                  </div>

                  {/* PRODUCT SELLING PRICE */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      PRODUCT SELLING PRICE
                    </label>
                    <input
                      type="number"
                      value={formData.sellingPrice}
                      onChange={(evt) =>
                        setFormData({
                          ...formData,
                          sellingPrice: evt.target.value,
                        })
                      }
                      className="form-control"
                      placeholder={"Enter product selling price"}
                    />
                  </div>

                  {/* PRODUCT STOCK */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      PRODUCT STOCK
                    </label>
                    <input
                      type="number"
                      value={formData.stock}
                      onChange={(evt) =>
                        setFormData({ ...formData, stock: evt.target.value })
                      }
                      className="form-control"
                      placeholder={"Enter product stock"}
                    />
                  </div>

                  {/* MAXIMUM ORDER QUANTITY */}
                  <div className={"form-group col-md-6"}>
                    <label htmlFor="" className="text-dark h6 active">
                      MAXIMUM ORDER QUANTITY
                    </label>
                    <input
                      type="text"
                      value={formData.maximumOrderQuantity}
                      onChange={(evt) =>
                        setFormData({
                          ...formData,
                          maximumOrderQuantity: evt.target.value,
                        })
                      }
                      className="form-control"
                      placeholder={"2"}
                    />
                  </div>

                  {/* PRODUCT IMAGES */}
                  <div className={""}>
                    {/* PRODUCT DEFAULT IMAGE */}
                    <div className="col-md-12">
                      <div className="row">
                        {/* PRODUCT DEFAULT IMAGE */}
                        <div className={"form-group col-md-6"}>
                          <label htmlFor="" className="text-dark h6 active">
                            PRODUCT DEFAULT IMAGE
                          </label>
                          <input
                            type="file"
                            multiple
                            onChange={(evt) =>
                              imageChangeHandler(evt, "default_image")
                            }
                            className="form-control"
                          />
                        </div>

                        {/* DEFAULT IMAGE */}
                        <div className="col-md-2">
                          {defaultImages ? (
                            <div className={"form-group"}>
                              <img
                                style={{
                                  maxHeight: "100%",
                                  maxWidth: "100%",
                                  border: "1px solid #5a5a5a",
                                }}
                                src={defaultImages}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  top: "40%",
                                  right: "45%",
                                }}
                                type="button"
                                className="btn bg-light text-danger"
                                title={"Delete Image"}
                                onClick={(evt) =>
                                  fileDeleteHandler(
                                    defaultImages,
                                    "",
                                    "default_image"
                                  )
                                }
                              >
                                X
                              </button>
                            </div>
                          ) : (
                            ""
                          )}

                          {defaultImgProgress ? (
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${defaultImgProgress}%` }}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {defaultImgProgress}%
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Products Multiple Images */}
                    <div className={"form-group col-md-12"}>
                      <label htmlFor="" className="text-dark h6 active">
                        PRODUCT IMAGES
                      </label>
                      <input
                        type="file"
                        multiple
                        onChange={imageChangeHandler}
                        className="form-control"
                      />
                    </div>

                    {/*Multiple Image Preview */}
                    <div className="col-md-12 mb-1">
                      <div className="row">
                        {previewImages.map((img, index) => {
                          return (
                            <div className={"form-group col-md-2"} key={index}>
                              <img
                                style={{
                                  maxHeight: "100%",
                                  maxWidth: "100%",
                                  border: "1px solid #5a5a5a",
                                }}
                                src={img}
                              />
                              <button
                                style={{
                                  position: "absolute",
                                  top: "40%",
                                  right: "45%",
                                }}
                                type="button"
                                className="btn bg-light text-danger"
                                title={"Delete Image"}
                                onClick={(evt) => fileDeleteHandler(img, index)}
                              >
                                X
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* Multiple image prpgress */}
                    <div className="col-md-12 mb-2">
                      <div className="row">
                        {progressInfos.map((info, index) => {
                          return (
                            <div className="col-md-2" key={index}>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${info}%` }}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {info}%
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="addModalCloseButton"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-info"
                form="addColorForm"
                disabled={isAddLoading}
              >
                {isAddLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading..
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Update Size Model */}
      <div
        className="modal fade"
        id="updateSizeModal"
        tabindex="-1"
        aria-labelledby="updateSizeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateSizeModalLabel">
                Update Size
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                className="form-horizontal form-material"
                onSubmit={updateHandler}
                id="updateSizeForm"
              >
                {/* SELECT SIZE */}
                {/* <div className={"form-group overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT SIZE
                  </label>
                  <div className="">
                    <Select
                      options={sizes}
                      value={updateFormData?.size}
                      onChange={(value) => {
                        setUpdateFormData({ ...updateFormData, size: value });
                      }}
                    />
                  </div>
                </div> */}

                {/* PRODUCT MRP */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT MRP
                  </label>
                  <input
                    type="number"
                    value={updateFormData.mrp}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        mrp: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product mrp"}
                  />
                </div>

                {/* PRODUCT SELLING PRICE */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SELLING PRICE
                  </label>
                  <input
                    type="number"
                    value={updateFormData.sellingPrice}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        sellingPrice: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product selling price"}
                  />
                </div>

                {/* PRODUCT STOCK */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT STOCK
                  </label>
                  <input
                    type="number"
                    value={updateFormData.stock}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        stock: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product stock"}
                  />
                </div>

                {/* MAXIMUM ORDER QUANTITY */}
                <div className={"form-group"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM ORDER QUANTITY
                  </label>
                  <input
                    type="text"
                    value={updateFormData.maximumOrderQuantity}
                    onChange={(evt) =>
                      setUpdateFormData({
                        ...updateFormData,
                        maximumOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"2"}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="updateModalCloseButton"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-info"
                form="updateSizeForm"
                disabled={sizeUpdating}
              >
                {sizeUpdating ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading..
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* -- Delete Size Modal -- */}
      <div
        className="modal fade rounded"
        id="deleteSizeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteSizeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded">
            <div className="modal-body text-center">
              <img
                style={{ width: "150px" }}
                className={"img img-fluid"}
                src={
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQ5R1g82DqzH4itsxpVCofNGWbAzKN_PJDBew&usqp=CAU"
                }
              />
              <h4 className={"text-center mt-2"}>Do You Want to Delete?</h4>

              <div className={"form-group"}>
                <button
                  className="btn btn-danger rounded px-3"
                  type={"submit"}
                  onClick={deleteSubmitHandler}
                >
                  {sizeDeleting ? (
                    <div>
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading..
                    </div>
                  ) : (
                    <div>
                      <i className="fas fa-trash"></i> Yes
                    </div>
                  )}
                </button>
                <button
                  className="btn btn-secondary rounded ml-2 px-3"
                  data-dismiss="modal"
                  id={"closeDeleteSizeModalButton"}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductColors;
