import React, { useContext, useState, useEffect } from "react";
import { Link, BrowserRouter, useHistory } from "react-router-dom";
import { AdminContext } from "./AdminRouter";
import $ from "jquery";

function LeftNavigation() {
  const history = useHistory();
  const { state, dispatch } = useContext(AdminContext);
  const [adminRights, setAdminRights] = useState({});

  // Login Function
  const logout = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("adminRights");
    localStorage.removeItem("jwt_admin_token");
    dispatch({ type: "CLEAR" });
    history.push("/admin/login");
  };

  // Remove Left Navigation When Click On The Link
  const removeLeftNavigation = (evt) => {
    $("body").removeClass("show-sidebar");
  };

  useEffect(() => {
    const admin_rights = JSON.parse(localStorage.getItem("adminRights"));
    if (admin_rights) setAdminRights(admin_rights);
  }, []);

  // Return Function
  return (
    <div>
      {state && (
        <aside className="left-sidebar">
          {/* <!-- Sidebar scroll--> */}
          <div className="scroll-sidebar">
            {/* <!-- User profile --> */}
            <div
              className="user-profile"
              style={{
                background:
                  "url(../assets/images/background/user-info.jpg) no-repeat",
              }}
            >
              {/* <!-- User profile image --> */}
              <div className="profile-img text-center">
                {state.profile_picture ? (
                  <img src={state.profile_picture} alt="user" />
                ) : (
                  <span
                    className={"fas fa-user-circle text-white"}
                    style={{ fontSize: "51px" }}
                  />
                )}
              </div>
              {/* <!-- User profile text--> */}
              <div className="profile-text">
                <Link
                  to="/admin"
                  className="dropdown-toggle u-dropdown"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {state.name}
                </Link>

                <div className="dropdown-menu animated flipInY">
                  <Link
                    to="/admin/profile"
                    className="dropdown-item"
                    onClick={removeLeftNavigation}
                  >
                    <i className="ti-user"></i> My Profile
                  </Link>

                  <div className="dropdown-divider"></div>

                  <Link
                    to="/admin/profile"
                    className="dropdown-item"
                    onClick={removeLeftNavigation}
                  >
                    <i className="ti-settings"></i> Account Setting
                  </Link>

                  <div className="dropdown-divider"></div>

                  <Link className="dropdown-item" to={"#"} onClick={logout}>
                    <i className="fa fa-power-off"></i> Logout
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- End User profile text--> */}

            {/* <!-- Sidebar navigation--> */}
            <nav className="sidebar-nav">
              <ul id="sidebarnav">
                <li className="nav-small-cap">PERSONAL</li>
                {/* Dashboard */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/admin"
                    onClick={removeLeftNavigation}
                  >
                    <i className="mdi mdi-gauge"></i>
                    <span className="hide-menu">Dashboard </span>
                  </Link>
                </li>

                {/* Setup Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-settings"></i>
                    <span className="hide-menu">SETUP</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.setup?.mainMenu ? (
                      <li>
                        <Link
                          to="/admin/mainMenus"
                          onClick={removeLeftNavigation}
                        >
                          Main Menu
                        </Link>
                      </li>
                    ) : null}

                    {state.adminType == "SUPER_ADMIN" ||
                    state?.setup?.setting ? (
                      <li>
                        <Link
                          to="/admin/setting"
                          onClick={removeLeftNavigation}
                        >
                          Setting
                        </Link>
                      </li>
                    ) : null}

                    {/* Pincode */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.pincode?.pincodeList ? (
                      <li>
                        <Link
                          to="/admin/pincodes"
                          onClick={removeLeftNavigation}
                        >
                          Pincodes
                        </Link>
                      </li>
                    ) : null}

                    {/* Coupon */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.coupon?.couponList ? (
                      <li>
                        <Link
                          to="/admin/coupons"
                          onClick={removeLeftNavigation}
                        >
                          Coupons
                        </Link>
                      </li>
                    ) : null}

                    {/* <li>
                      <Link
                        to="/admin/occasions"
                        onClick={removeLeftNavigation}
                      >
                        Occasion
                      </Link>
                    </li> */}

                    {/* Coupon */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.color?.colorList ? (
                      <li>
                        <Link to="/admin/colors" onClick={removeLeftNavigation}>
                          Colors
                        </Link>
                      </li>
                    ) : null}

                    {/* Size */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.size?.sizeList ? (
                      <li>
                        <Link to="/admin/sizes" onClick={removeLeftNavigation}>
                          Sizes
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>

                {/* Admin Section */}
                {adminRights.adminType == "SUPER_ADMIN" ||
                adminRights.orderList ? (
                  <li>
                    <Link
                      className="has-arrow waves-dark"
                      to="/"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-account"></i>
                      <span className="hide-menu">ADMINS</span>
                    </Link>

                    <ul aria-expanded="false" className="collapse">
                      {/* Size */}
                      {state.adminType == "SUPER_ADMIN" ||
                      state?.admin?.adminList ? (
                        <li>
                          <Link
                            to="/admin/subAdmins"
                            onClick={removeLeftNavigation}
                          >
                            Admin Lists
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                {/* Users Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-account-plus"></i>
                    <span className="hide-menu">USERS</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    {/* User Lists */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.user?.userList ? (
                      <li>
                        <Link
                          to="/admin/customers"
                          onClick={removeLeftNavigation}
                        >
                          User Lists
                        </Link>
                      </li>
                    ) : null}

                    {/* Newsletter Emails */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.newsletter?.newsletterList ? (
                      <li>
                        <Link
                          to="/admin/newsletters"
                          onClick={removeLeftNavigation}
                        >
                          Newsletter Emails
                        </Link>
                      </li>
                    ) : null}

                    {/* Inquiries */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.inquiry?.inquiryList ? (
                      <li>
                        <Link
                          to="/admin/inquires"
                          onClick={removeLeftNavigation}
                        >
                          Inquires
                        </Link>
                      </li>
                    ) : null}

                    {/* Testimonial Users */}
                    {state.adminType == "SUPER_ADMIN" ||
                    state?.testimonial?.testimonialList ? (
                      <li>
                        <Link
                          to="/admin/testimonials"
                          onClick={removeLeftNavigation}
                        >
                          Testimonial User
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>

                {/* Banners Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-image-area"></i>
                    <span className="hide-menu">BANNERS</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    <li>
                      <Link to="/admin/slider" onClick={removeLeftNavigation}>
                        Main Slider
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/admin/nextToSlider"
                        onClick={removeLeftNavigation}
                      >
                        Next to Slider
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/admin/bestSaleBanner"
                        onClick={removeLeftNavigation}
                      >
                        Best Sale Banner
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/admin/promotionBanner"
                        onClick={removeLeftNavigation}
                      >
                        Promotion Banner
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/admin/shopPageBanner"
                        onClick={removeLeftNavigation}
                      >
                        Shop Page Banner
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/admin/offerBanner"
                        onClick={removeLeftNavigation}
                      >
                        Offer Banner
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Category */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-book-open-variant"></i>
                    <span className="hide-menu">CATEGORIES</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    <li>
                      <Link
                        to="/admin/categories"
                        onClick={removeLeftNavigation}
                      >
                        Category
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/admin/subCategories"
                        onClick={removeLeftNavigation}
                      >
                        Sub Category
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Projects */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-book-open-variant"></i>
                    <span className="hide-menu">PROJECTS</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    <li>
                      <Link
                        to="/admin/projectCategories"
                        onClick={removeLeftNavigation}
                      >
                        Project Category
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/admin/projectSubCategories"
                        onClick={removeLeftNavigation}
                      >
                        Project Sub Category
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/admin/projectChildCategories"
                        onClick={removeLeftNavigation}
                      >
                        Project Child Category
                      </Link>
                    </li>

                    <li>
                      <Link to="/admin/projects" onClick={removeLeftNavigation}>
                        Projects
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Product Section */}
                {adminRights.adminType == "SUPER_ADMIN" ||
                adminRights.productList ? (
                  <li>
                    <Link
                      className="has-arrow waves-dark"
                      to="/"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-note-multiple-outline"></i>
                      <span className="hide-menu">PRODUCTS</span>
                    </Link>

                    <ul aria-expanded="false" className="collapse">
                      {/* Product List */}
                      {state?.adminType == "SUPER_ADMIN" ||
                      state?.product?.productList ? (
                        <li>
                          <Link
                            to="/admin/products"
                            onClick={removeLeftNavigation}
                          >
                            Product List
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                {/* Reviews Section */}
                {adminRights.adminType == "SUPER_ADMIN" ||
                adminRights.reviewList ? (
                  <li>
                    <Link
                      className="has-arrow waves-dark"
                      to="/"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-note-multiple-outline"></i>
                      <span className="hide-menu">REVIEWS</span>
                    </Link>

                    <ul aria-expanded="false" className="collapse">
                      {/* Rating & Reviews */}
                      {state?.adminType == "SUPER_ADMIN" ||
                      state?.review?.reviewList ? (
                        <li>
                          <Link
                            to="/admin/reviews"
                            onClick={removeLeftNavigation}
                          >
                            Rating & Reviews
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                {/* Order Section */}
                {adminRights.adminType == "SUPER_ADMIN" ||
                adminRights.orderList ? (
                  <li>
                    <Link
                      className="has-arrow waves-dark"
                      to="/"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-cart"></i>
                      <span className="hide-menu">ORDERS</span>
                    </Link>

                    <ul aria-expanded="false" className="collapse">
                      {/* Today Order */}
                      {state?.adminType == "SUPER_ADMIN" ||
                      state?.order?.todayOrder ? (
                        <li>
                          <Link
                            to="/admin/orders?startDate=new"
                            onClick={removeLeftNavigation}
                          >
                            Today Orders
                          </Link>
                        </li>
                      ) : null}

                      {/* Order List */}
                      {state?.adminType == "SUPER_ADMIN" ||
                      state?.order?.orderList ? (
                        <li>
                          <Link
                            to="/admin/orders"
                            onClick={removeLeftNavigation}
                          >
                            Order List
                          </Link>
                        </li>
                      ) : null}

                      {/* Order History List */}
                      {state?.adminType == "SUPER_ADMIN" ||
                      state?.order?.orderHistoryList ? (
                        <li>
                          <Link
                            to="/admin/orderHistories"
                            onClick={removeLeftNavigation}
                          >
                            Order History
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                {/* Web Content Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-note-text"></i>
                    <span className="hide-menu">WEB CONTENTS</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    {/* About Us */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.webContent?.aboutUs ? (
                      <li>
                        <Link
                          to="/admin/aboutUs"
                          onClick={removeLeftNavigation}
                        >
                          About Us
                        </Link>
                      </li>
                    ) : null}

                    {/* Contact Us */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.webContent?.contactUs ? (
                      <li>
                        <Link
                          to="/admin/contactUs"
                          onClick={removeLeftNavigation}
                        >
                          Contact Us
                        </Link>
                      </li>
                    ) : null}

                    {/* Terms & Conditions */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.webContent?.termsAndConditions ? (
                      <li>
                        <Link
                          to="/admin/termsConditions"
                          onClick={removeLeftNavigation}
                        >
                          Terms & Conditions
                        </Link>
                      </li>
                    ) : null}

                    {/* Privacy & Policy */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.webContent?.privacyPolicy ? (
                      <li>
                        <Link
                          to="/admin/privacyPolicy"
                          onClick={removeLeftNavigation}
                        >
                          Privacy & Policy
                        </Link>
                      </li>
                    ) : null}

                    {/* Return & Refund Policy */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.webContent?.privacyPolicy ? (
                      <li>
                        <Link
                          to="/admin/returnRefundPolicy"
                          onClick={removeLeftNavigation}
                        >
                          Return & Refund Policy
                        </Link>
                      </li>
                    ) : null}

                    {/* Faqs */}
                    <li>
                      <Link to="/admin/faqs" onClick={removeLeftNavigation}>
                        Faqs
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Blog Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-note-text"></i>
                    <span className="hide-menu">BLOG</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    {/* Blog Category */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.blogCategory?.blogCategoryList ? (
                      <li>
                        <Link
                          to="/admin/blogCategories"
                          onClick={removeLeftNavigation}
                        >
                          Category
                        </Link>
                      </li>
                    ) : null}

                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.blogPost?.blogPostList ? (
                      <li>
                        <Link
                          to="/admin/blogPosts"
                          onClick={removeLeftNavigation}
                        >
                          Blog Post
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>

                {/* Report Section */}
                <li>
                  <Link
                    className="has-arrow waves-dark"
                    to="/"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-chart-bar"></i>
                    <span className="hide-menu">REPORTS</span>
                  </Link>

                  <ul aria-expanded="false" className="collapse">
                    {/* Top 10 Customers */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.report?.topCustomers ? (
                      <li>
                        <Link
                          to="/admin/report/customers"
                          onClick={removeLeftNavigation}
                        >
                          Top 10 Customers
                        </Link>
                      </li>
                    ) : null}

                    {/* Top 10 Prodcucts */}
                    {state?.adminType == "SUPER_ADMIN" ||
                    state?.report?.topProducts ? (
                      <li>
                        <Link
                          to="/admin/report/products"
                          onClick={removeLeftNavigation}
                        >
                          Top 10 Prodcucts
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              </ul>
            </nav>
            {/* <!-- End Sidebar navigation --> */}
          </div>
          {/* <!-- End Sidebar scroll--> */}
          {/* <!-- Bottom points--> */}
          <div className="sidebar-footer">
            {/* <!-- item--> */}
            <Link
              to="/admin/setting"
              className="link"
              data-toggle="tooltip"
              title="Settings"
              onClick={removeLeftNavigation}
            >
              <i className="ti-settings"></i>
            </Link>
            {/* <!-- item--> */}
            <Link
              to="#"
              className="link"
              data-toggle="tooltip"
              title="Email"
              onClick={removeLeftNavigation}
            >
              <i className="mdi mdi-gmail"></i>
            </Link>
            {/* <!-- item--> */}

            <Link
              to="#"
              onClick={(evt) => logout(evt)}
              className="link"
              data-toggle="tooltip"
              title="Logout"
            >
              <i className="mdi mdi-power"></i>
            </Link>
          </div>
          {/* <!-- End Bottom points--> */}
        </aside>
      )}
    </div>
  );
}

export default LeftNavigation;
